import { render } from "./AgreementPrivacy.vue?vue&type=template&id=510073c5"
import script from "./AgreementPrivacy.ts?vue&type=script&lang=ts"
export * from "./AgreementPrivacy.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "510073c5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('510073c5', script)) {
    api.reload('510073c5', script)
  }
  
  module.hot.accept("./AgreementPrivacy.vue?vue&type=template&id=510073c5", () => {
    api.rerender('510073c5', render)
  })

}

script.__file = "src/views/AgreementPrivacy/AgreementPrivacy.vue"

export default script